import React from "react"
import {
  ActivityBox,
  CopyrightBox,
  DefinitionBox,
  DiscussBox,
  QuestionBox,
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import stonemasonWheelFrame from './stonemason-wheel.jpg'
import { Video } from '../../../components/video'
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const Stone = () => (
  <Layout title="Stone">
    <SmallSectionHeader
      color={theme.colors.yellow.dark}
      image={headerImage}
      to="/how-did-they-build-that"
    >
      How did they build that?
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/how-did-they-build-that', name: 'How did they build that?' },
      { name: 'Stone' }
    ]} />
    <Page>
      <h1>Stone</h1>
      <div className="page-left">
        <p>
          When the building we see today was constructed, it was easier to quarry (cut from the ground) and bring stone by boat from France, than it was to travel across land on poor roads with cartloads of heavy stone.
        </p>
        <p>
          In the medieval period building projects at the Cathedral were led by the master mason (a craftsperson who works with stone). He both designed the building and directed the construction work. The master mason appointed a master craftsman to each of the areas required for the project. These skilled craftspeople moved around the country working on different building projects and developing their craft. Some were paid by the day and others were paid by ‘piece’ of work completed. Stone cutters made a mark on every stone they cut so they could prove how much they should be paid. These ‘makers marks’ later grew more complicated as masons created more and more decorated stone interiors and gained the status of artists as well as craftspeople. The stone-masons at Canterbury Cathedral still use their own masons marks today.
        </p>
        <DefinitionBox className="page-left" title="Quarry">
          A type of open-pit or mine where stone is dug from the ground.
        </DefinitionBox>
      </div>
      <div className="page-right">
        <Figure
          caption="Stone showing the different stonemasons’ marks in Canterbury Cathedral"
        >
          <StaticImage src="./masons-marks.jpg" alt="Masons Marks" />
        </Figure>
        <QuestionBox title="Restoration" className="page-right">
          Highly skilled, trained stone masons continue to work on the building today to conserve and repair the historic stonework.
        </QuestionBox>
      </div>

      <DiscussBox>
        What properties does stone have that make it a good material for building a cathedral?
      </DiscussBox>

      <h2>Step-by-step</h2>
      <div className="page-left">
        <p>
          Building projects in the medieval Cathedral followed a number of steps.
        </p>
        <ol>
          <li>
            The foundations (base of the building below the ground) are laid. The current Cathedral building is built on earlier foundations from the Norman and Saxon buildings.
          </li>
          <li>
            The master mason in charge of the new building provides templates for the stones he needs.
          </li>
          <li>
            The stone is quarried and cut. Small stones might be cut on site.
          </li>
          <li>
            The stones are transported to The Cathedral either by boat, or else one-by-one using an ox or horse drawn cart.
          </li>
          <li>
            The stones are laid in place. Scaffolding is used to read the higher parts and stones are lifted using cranes and pulleys.
          </li>
          <li>
            Arches are created using wooden scaffolding called ‘centring’ which is removed once the ‘keystone’ locks the arch in place.
          </li>
          <li>
            Roof timbers are added and the roof is covered in lead.
          </li>
          <li>
            Glass windows in metal frames are set in the windows.
          </li>
        </ol>
      </div>
      <Figure
        className="page-right row-2"
      >
        <StaticImage
          src="./stonemason.png"
          alt="Stonemason"
        />
      </Figure>

      <Figure
        caption="Jordan Cliffe, stonemason apprentice, explains how the treadbarrel wheel in the Bell Harry tower worked to move the building materials."
      >
        <Video placeholder={stonemasonWheelFrame}
          sources={[
            { type: "mp4", src: "stonemason-wheel.mp4" },
            { type: "webm", src: "stonemason-wheel.webm" }
          ]}
        />
      </Figure>

      <h2>Treadwheel in Bell Harry Tower</h2>
      <p>
      The Bell Harry Tower is the tallest part of the Cathedral building and was completed in 1498. High up in the tower there still remains the original treadwheel, used to lift the heavy stones, bricks and tools into place when the tower was built. The central shield and cross you can see when you look up into the tower from inside the Cathedral is a trap door and can be removed, leading to a space containing a ‘hamster wheel’ which is large enough for two men. Amazingly this medieval ‘crane’ was still used up until the 1970s when it failed to pass modern Health and Safety tests.
      </p>

      <DiscussBox>
      What challenges did the master mason face working with stone?
      </DiscussBox>

      <h2>Activities</h2>
      <ActivityBox
        title="How Did They Build That activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/HowDidTheyBuildThat.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘How did they build that ’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "Building the Cathedral", to: "/how-did-they-build-that/building-the-cathedral" },
          { name: "Stained Glass", to: "/how-did-they-build-that/stained-glass" },
          { name: "How is glass made?", to: "/how-did-they-build-that/glass" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default Stone;